import './App.css';
import { Col, Container, Image, Row, InputGroup, Button, Form } from 'react-bootstrap';
import BackGroundVideo from './assets/home.mp4';
import Logo from './assets/logo.svg';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;



const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = stratTime + 3432480; // use UNIX timestamp in seconds
  const [email, setEmail] = useState('');

  const timerProps = {
    isPlaying: true,
    size: windowWidth > 768 ? 200 : 80,
    strokeWidth: windowWidth > 768 ? 10 : 3,
  };

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendMail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailRegex.test(email) == false) {
      toast.error('📧 The email was invalid.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "dark",
      });
      return;
    }

    let data = {
      email: email,
    };
    let headers = {
      'accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/sendMail.php',
      headers: headers,
      data: data
    };

    axios.request(config)
      .then((response) => {
        toast.success('📧 Email has been sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "dark",
        });
      })
      .catch((error) => {
        toast.error('📧 Email was not sent', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "dark",
        });
      });
  }
  return (
    <div className="App py-5">
      <video autoPlay muted loop id='background_video'>
        <source source src={BackGroundVideo} type="video/mp4" />
      </video>
      <Container className='position-relative z-3'>
        <ToastContainer />
        <Row>
          <Col sm={12} md={12} className='text-center'>
            <div className='text-center sub-title mb-3'>NEW INNOVATIVE PLATFORM</div>
            <Image src={Logo} height="85" />
            <div className='main-title mb-5'>COMING SOON</div>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col sm={12} md={4} className='mx-auto'>
            <InputGroup className="mb-3 align-items-center">
              <Form.Control
                placeholder="example@gmail.com"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button variant="outline-secondary" id="button-addon2" onClick={sendMail}>
                Join the Alphalist
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row className='count-down-timer justify-content-center mb-5'>
          <Col xs={3} md={2} className='mb-3 '>
            <CountdownCircleTimer
              {...timerProps}
              colors="#F24F09"
              duration={daysDuration}
              initialRemainingTime={remainingTime}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </Col>
          <Col xs={3} md={2} className='mb-3 '>
            <CountdownCircleTimer
              {...timerProps}
              colors="#329000"
              duration={daySeconds}
              initialRemainingTime={remainingTime % daySeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </Col>
          <Col xs={3} md={2} className='mb-3'>
            <CountdownCircleTimer
              {...timerProps}
              colors="#DA2128"
              duration={hourSeconds}
              initialRemainingTime={remainingTime % hourSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </Col>
          <Col xs={3} md={2} className='mb-3'>
            <CountdownCircleTimer
              {...timerProps}
              colors="#3B0094"
              duration={minuteSeconds}
              initialRemainingTime={remainingTime % minuteSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > 0
              })}
            >
              {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime("seconds", getTimeSeconds(elapsedTime))}
                </span>
              )}
            </CountdownCircleTimer>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='text-center text-white copyright'>
              Copyright ©2023 BITNSWAP. | All Rights Reserved
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
